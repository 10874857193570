<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <div class="d-flex flex-column px-6 py-6 skeleton-loader">
        <v-skeleton-loader type="header" class="title" />
        <div>
          <v-skeleton-loader
            class="text"
            type="text, header"
            v-for="index in 3"
            :key="index"
          />
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-loader {
  height: 100%;
}

.v-skeleton-loader {
  &.title::v-deep {
    margin-bottom: 16px;
    .v-skeleton-loader__header {
      width: 120px;
      height: 10px;
      margin: 6px 0;
    }
  }
  &.text::v-deep {
    margin: 4px 0 0 0;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    .v-skeleton-loader__text {
      width: 96px;
      height: 6px;
      margin: 7px 0 14px;
    }
    .v-skeleton-loader__header {
      height: 10px;
      width: 120px;
      margin: 7px 0;
    }
  }
}
</style>