<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <div class="d-flex flex-column px-6 py-6 skeleton-loader">
        <v-skeleton-loader type="header" class="title" />

        <div class="d-flex">
          <div
            class="d-flex flex-column version-box"
            v-for="index in 2"
            :key="index"
          >
            <v-skeleton-loader class="version" type="text, chip, text" />
          </div>
        </div>

        <v-skeleton-loader type="header" class="button" />
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-loader {
  height: 100%;
  .version-box {
    width: 50%;
  }
}

.v-skeleton-loader {
  &.title::v-deep {
    margin-bottom: 16px;
    .v-skeleton-loader__header {
      width: 120px;
      height: 10px;
      margin: 6px 0;
    }
  }
  &.version::v-deep {
    margin: 4px 0;
    .v-skeleton-loader__text {
      width: 70px;
      height: 6px;
      margin: 7px 0 16px 0;
    }
    .v-skeleton-loader__chip {
      height: 30px;
      width: 86px;
      margin: 16px 0;
      border-radius: 3px;
    }
  }
  &.button::v-deep {
    margin-top: auto;
    .v-skeleton-loader__header {
      margin-top: 16px;
      width: 100%;
      height: 42px;
    }
  }
}
</style>