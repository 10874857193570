<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <div class="d-flex flex-column px-6 py-6 skeleton-loader">
        <v-skeleton-loader type="header" class="title" />

        <div
          v-for="index in 2"
          :key="index"
          class="d-flex justify-space-between align-center"
        >
          <v-skeleton-loader class="text mb-3" type="header, text" />
          <v-skeleton-loader type="chip" class="switch" />
        </div>

        <v-skeleton-loader type="header" class="button" />
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-loader {
  height: 100%;
}

.v-skeleton-loader {
  &.title::v-deep {
    margin-bottom: 16px;
    .v-skeleton-loader__header {
      width: 120px;
      height: 10px;
      margin: 6px 0;
    }
  }
  &.text::v-deep {
    margin: 4px 0;
    .v-skeleton-loader__text {
      width: 96px;
      height: 6px;
      margin: 14px 0 7px;
    }
    .v-skeleton-loader__header {
      height: 10px;
      width: 120px;
      margin: 6px 0;
    }
  }
  &.switch::v-deep {
    .v-skeleton-loader__chip {
      width: 45px;
      height: 28px;
      border-radius: 100px;
    }
  }
  &.button::v-deep {
    margin-top: auto;
    .v-skeleton-loader__header {
      margin-top: 8px;
      width: 100%;
      height: 42px;
    }
  }
}
</style>