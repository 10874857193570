<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <div class="d-flex flex-column px-6 py-6 skeleton-loader">
        <v-skeleton-loader type="header" class="title" />
        <div class="d-flex">
          <div class="d-flex flex-column speed-skeleton mr-3 ">
            <v-skeleton-loader type="avatar, text" class="speed" />
          </div>
          <div class="d-flex flex-column speed-skeleton ml-3">
            <v-skeleton-loader type="avatar, text" class="speed" />
          </div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-loader {
  height: 100%;
}
.speed-skeleton {
  width: 50%;
}

.v-skeleton-loader {
  &.title::v-deep {
    margin-bottom: 6px;
    .v-skeleton-loader__header {
      width: 120px;
      height: 10px;
      margin: 6px 0;
    }
  }
  &.speed::v-deep {
    margin: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    .v-skeleton-loader__avatar {
      width: 94px;
      height: 94px;
      background-color: #fff;
      border: 8px solid map-get($gray, lighten2);
    }
    .v-skeleton-loader__text {
      width: 40px;
      height: 10px;
      margin: 28px 0 22px 0;
    }
  }
}
</style>