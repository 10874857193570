<template>
  <v-progress-circular
    :value="displayData"
    :color="color"
    :size="size ? size : '96'"
    :width="width ? width : 8"
    rotate="270"
  >
    <h1
      :class="[
        iconColor ? iconColor : `${color}--text`,
        centerClass ? centerClass : '',
      ]"
      v-if="!hasIcon"
      class="value"
    >
      {{ typeof value == "number" ? Math.ceil(value) : value }}
    </h1>
    <h1 v-else :class="`${iconColor ? iconColor : color}--text`" class="value">
      <slot name="centerIcon" />
    </h1>
  </v-progress-circular>
</template>

<script>
export default {
  data: function () {
    return {
      displayData: 0,
    };
  },

  props: {
    value: {
      type: [String, Number],
      default: "-",
    },
    size: {
      type: [Number, String],
      default: 96,
    },
    width: {
      type: [Number, String],
      default: 8,
    },
    iconColor: {
      type: String,
      default: "",
    },
    centerClass: {
      type: String,
      default: "",
    },
    color: String,
  },
  mounted() {
    setTimeout(() => {
      this.displayData = this.value;
    }, 0);
  },
  computed: {
    hasIcon: function () {
      return this.$slots.centerIcon ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular::v-deep {
  .v-progress-circular__underlay {
    stroke: #E5E5F0;
  }
  .v-progress-circular__overlay {
    stroke-linecap: round;
  }
}
.value {
  font-weight: $font-weight-light;
  line-height: $line-height-sm;
}
</style>