<template>
  <v-col cols="12" xl="6" class="align-stretch d-flex responsive-card" v-if="isReportProviderEnabled">
    <v-card
      width="100%"
      elevation="4"
      class="analytics-card analytics-card--visitors"
    >
      <template v-if="!loading">
        <v-card-text>
          <v-tabs v-model="activeTab">
            <v-tab class="py-2" v-ripple="false">
              <div class="d-flex align-center">
                <v-icon
                  size="32"
                  :color="activeTab === 0 ? 'primary' : ''"
                  class="mr-4"
                >
                  $user
                </v-icon>
                <div class="d-flex flex-column">
                  <p
                    class="
                      p-4
                      mb-0
                      text-start
                      heading--text
                      font-weight-600
                      analytics-card__title
                    "
                    :class="activeTab !== 0 ? 'gray--text text--darken-1' : ''"
                  >
                    {{ $t('heading.instance.siteDetails.analytics.visitors.unique') }}
                  </p>
                  <div class="d-flex justify-space-between">
                    <template v-if="isReportProviderEnabled">
                      <p
                        class="current-data mb-0 heading--text font-weight-600"
                      >
                        {{ visitorsData.current ? visitorsData.current : 0 }}
                      </p>
                      <delta-indicator
                        v-if="visitorsData.delta"
                        class="mt-2"
                        :value="visitorsData.delta"
                      />
                    </template>
                    <template v-else>
                      <span class="gray--text text--darken-2 font-weight-600">
                        -
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </v-tab>
            <v-tab class="py-2" v-ripple="false" :disabled="!isReportProviderEnabled">
              <div class="d-flex align-center">
                <v-icon
                  size="32"
                  :color="activeTab === 1 ? 'primary' : ''"
                  class="mr-4"
                >
                  $logout
                </v-icon>
                <div class="d-flex flex-column">
                  <p
                    class="
                      p-4
                      mb-0
                      text-start
                      heading--text
                      font-weight-600
                      analytics-card__title
                    "
                    :class="activeTab !== 1 ? 'gray--text text--darken-1' : ''"
                  >
                    {{ $t('heading.instance.siteDetails.analytics.visitors.session') }}
                  </p>
                  <div class="d-flex justify-space-between">
                    <template v-if="isReportProviderEnabled">
                      <p
                        class="current-data mb-0 heading--text font-weight-600"
                      >
                        {{
                          sessionTimeData.current ? sessionTimeData.current : 0
                        }}
                      </p>
                      <delta-indicator
                        v-if="sessionTimeData.delta"
                        class="mt-2"
                        :value="sessionTimeData.delta"
                      />
                    </template>
                    <template v-else>
                      <span class="gray--text text--darken-2 font-weight-600">
                        -
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </v-tab>
          </v-tabs>
          <template v-if="!isReportProviderEnabled">
            <div
              class="
                no-data-container
                d-flex
                align-center
                justify-center
                flex-column
                mx-auto
              "
              style="height: 220px; max-width: 500px"
            >
              <svg
                width="64"
                height="65"
                viewBox="0 0 64 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M50 11.52L46 7.52002"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M46 11.52L50 7.52002"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.074 45.7268V53.2677C10.074 53.2677 10.074 53.3052 10.074 53.3239C10.074 56.194 18.5677 58.52 29.037 58.52C39.5064 58.52 47.9447 56.2127 48.0001 53.3427V45.4454L47.4831 44.8264L10.074 45.708V45.7268Z"
                  fill="white"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M29.037 50.4915C39.5101 50.4915 48.0001 48.1651 48.0001 45.2954C48.0001 42.4257 39.5101 40.0993 29.037 40.0993C18.564 40.0993 10.074 42.4257 10.074 45.2954C10.074 48.1651 18.564 50.4915 29.037 50.4915Z"
                  fill="white"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M10.074 34.0779V41.5062C10.074 41.5062 10.074 41.5438 10.074 41.5625C10.074 44.4325 18.5677 46.7586 29.037 46.7586C39.5064 46.7586 47.9447 44.4513 48.0001 41.5813V34.0779H10.074Z"
                  fill="#EEEEF4"
                  stroke="#77849F"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M29.037 38.73C39.5101 38.73 48.0001 36.4036 48.0001 33.5339C48.0001 30.6642 39.5101 28.3378 29.037 28.3378C18.564 28.3378 10.074 30.6642 10.074 33.5339C10.074 36.4036 18.564 38.73 29.037 38.73Z"
                  fill="#EEEEF4"
                  stroke="#77849F"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M47.9261 22.1475L10 22.2038V29.6884C10 29.6884 10 29.7259 10 29.7447C10 32.6147 18.4937 34.9407 28.9631 34.9407C39.3955 34.9407 47.8707 32.6335 47.9261 29.7634V22.1475Z"
                  fill="white"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M28.9631 26.9122C39.4361 26.9122 47.9261 24.5858 47.9261 21.7161C47.9261 18.8464 39.4361 16.52 28.9631 16.52C18.4901 16.52 10 18.8464 10 21.7161C10 24.5858 18.4901 26.9122 28.9631 26.9122Z"
                  fill="white"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M44.0551 41.52C44.5988 41.52 45.0395 41.0723 45.0395 40.52C45.0395 39.9677 44.5988 39.52 44.0551 39.52C43.5115 39.52 43.0708 39.9677 43.0708 40.52C43.0708 41.0723 43.5115 41.52 44.0551 41.52Z"
                  fill="#77849F"
                />
                <path
                  d="M44.0551 53.52C44.5988 53.52 45.0395 53.0723 45.0395 52.52C45.0395 51.9677 44.5988 51.52 44.0551 51.52C43.5115 51.52 43.0708 51.9677 43.0708 52.52C43.0708 53.0723 43.5115 53.52 44.0551 53.52Z"
                  fill="#B0B0D3"
                />
                <path
                  d="M44.0551 29.52C44.5988 29.52 45.0395 29.0723 45.0395 28.52C45.0395 27.9677 44.5988 27.52 44.0551 27.52C43.5115 27.52 43.0708 27.9677 43.0708 28.52C43.0708 29.0723 43.5115 29.52 44.0551 29.52Z"
                  fill="#B0B0D3"
                />
                <path
                  d="M58 20.52C59.1046 20.52 60 19.6246 60 18.52C60 17.4155 59.1046 16.52 58 16.52C56.8954 16.52 56 17.4155 56 18.52C56 19.6246 56.8954 20.52 58 20.52Z"
                  stroke="#77849F"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>

              <h5 class="p-3 gray--text text--darken-2 mb-2 mt-4">
                {{ $t('message.emptyTable.visitors.title') }}
              </h5>
              <p class="text-center p-4">
                {{ $t('message.emptyTable.visitors.description') }}
              </p>
            </div>
          </template>
          <v-tabs-items v-else v-model="activeTab" class="">
            <v-tab-item>
              <visitors-chart
                v-if="!loading"
                :style="options"
                :chartdata="visitorsData"
              ></visitors-chart>
            </v-tab-item>
            <v-tab-item>
              <visitors-chart
                v-if="!loading"
                :style="options"
                :type="'Session Time'"
                :time="true"
                :chartdata="sessionTimeData"
              ></visitors-chart>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </template>
      <template v-else>
        <chart-card-skeleton />
      </template>
    </v-card>
  </v-col>
</template>

<script>
import VisitorsChart from "../charts/VisitorsChart.vue";
import DeltaIndicator from "../DeltaIndicator.vue";
import ChartCardSkeleton from "./ChartCardSkeleton.vue";

export default {
  components: {
    VisitorsChart,
    DeltaIndicator,
    ChartCardSkeleton,
  },
  data: () => ({
    activeTab: 0,
    options: {
      position: "relative",
      height: "220px",
    },
  }),
  computed: {
    isReportProviderEnabled() {
      return this.$store.state.home.app.report_provider_enabled;
    },
  },
  props: {
    loading: Boolean,
    fetching: Boolean,
    visitorsData: {
      type: Object,
      default: () => ({
        current: 0,
        delta: 0,
      }),
    },
    sessionTimeData: {
      type: Object,
      default: () => ({
        current: 0,
        delta: 0,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.current-data {
  font-size: $font-size-xlg;
  line-height: $line-height-xlg;
}
</style>
