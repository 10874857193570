<template>
  <div class="chart-container">
    <ApexChart
      ref="dailyLine"
      height="250"
      type="area"
      :options="options"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
import ApexChart from "vue-apexcharts";
import Helper from "@/apis/Helper";

export default {
  components: {
    ApexChart,
  },
  props: {
    chartdata: Object,
  },
  mounted() {
    if(this.isRTL) {
      this.chartdata.datasets[0] = this.chartdata.datasets[0].reverse()
      this.chartdata.labels = this.chartdata.labels.reverse()
    }
  },
  computed: {
    isRTL() {
      return this.$vuetify.rtl
    },
    series() {
      return [
        {
          name: "Total",
          data: this.chartdata.datasets[0],
        },
      ];
    },
  },
  data() {
    return {
      options: {
        colors: ["#005EEA"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.0,
            opacityFrom: 0.4,
            opacityTo: 0.05,
            stops: [0, 100],
          },
        },

        stroke: {
          colors: ["#005EEA"],
          curve: "straight",
          width: 2,
        },
        width: "100%",
        tooltips: {
          xaxis: {},
        },
        grid: {
          borderColor: "#DEDEF1",
          strokeDashArray: 9,
          borderWidth: "1px",
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          // type: "datetime",
          categories: this.chartdata.labels,
          labels: {
            minHeight: 76,
            rotate: 0,
            hideOverlappingLabels: true,
            style: {
              colors: "#797997",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "700",
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: function (value) {
              return Helper.formatBytes(value);
            },
            style: {
              colors: "#797997",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "700",
            },
          },
          tickAmount: 5,
          opposite: this.$vuetify.rtl
        },
        chart: {
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        legend: {
          itemMargin: {
            vertical: 20,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-container::v-deep {
  .apexcharts-canvas {
    margin-bottom: 12px;
  }
  .apexcharts-legend {
    margin-top: -24px;
    justify-content: space-between !important;

    .apexcharts-legend-series {
      margin-left: 4px !important;
      margin-right: 4px !important;
    }
  }
}
</style>
